import React, { useEffect, useState } from 'react'
import { Stack, Typography, CardMedia, Button, Box, Tab, Tabs, Divider, Dialog, Card, useMediaQuery } from '@mui/material'
import maleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import StethIcon from '../../assets/images/stethoscope.svg';
import followIcon from '../../assets/images/Follow.svg';
import followWhiteIcon from '../../assets/images/Doctor-follow.svg';
import CommunicationImg from '../../assets/images/Communication-language.svg';
import { ContentCopy, Email, EventAvailable, Facebook, LinkedIn, ShareOutlined, Twitter, WhatsApp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Overview from './Overview';
import MedicalService from './Medicalservice';
import PostPublications from './PostPublications';
import { WhatsappShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import './doctor.css'
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { localstore } from '../localstore/localstore';
import URL from '../../services/api_url';
import axios from 'axios';
import DoctorUnfollow from './doctorUnfollow';
import NoAccess from '../atoms/NoAccess';
import BranchUrl from '../../services/api_branch_url';

const CustomButton = (props) => {
    return (
        <Button className="add-option" sx={{ mt: '0px!important', mr: 2, fontWeight: '550!important', padding: '5px 12px!important', color: '#E44190' }} variant="outlined">{props.children}&nbsp;{props.name}</Button>
    )
}


const CustomRow = (props) => {
    return (
        <Typography component="div" sx={{ px: 2, pt: 2 }} >
            <Typography component="p" sx={{ pb: 1 }} className="flex items-center">
                {props.children}
            </Typography>
            {props.true ? '' : <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />}
        </Typography>
    )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3, overflowY: 'scroll', height: 'calc(100% - 480px)', paddingLeft: '1px', paddingRight: '1px' }} className="doctorprofiletab">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function DocProfileHeader({ practitionerrole, userData, doctorData, id, selectedbranch, setOpenShedule }) {

    const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)
    const branchName = useSelector((state) => state.branch.branch)
    const usertoken = localstore.getToken()
    const [followText, setFollowText] = useState("Follow");
    const [beingFollowed, setBeingFollowed] = useState()
    const [isFollowing, setIsFollowing] = useState();
    const [followData, setFollowData] = useState(null)
    const [openMessage, setOpenMessage] = useState(false)
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false);
    const [ind, setInd] = useState('')
    const [modal, setModal] = useState(false)
    const urls = BranchUrl()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const title = userData?.name?.[0]?.text
    const url1 = `https://www.paramithahospitals.in/${branchName ? `${branchName?.replace(/[\s,]+/g, '%20')}/doctor/${id}` : `/doctor/${id}`}`;
    const HandleCopy = () => {
        navigator.clipboard.writeText(url1)

        // const baseUrl =
        //     process.env.NODE_ENV === 'development'
        //         ? 'http://localhost:3000/' // Local development URL
        //         : process.env.REACT_APP_WEB_URL; // Production URL from environment variables

        // const copyText = `${baseUrl}${selectedbranch?.name ? `${selectedbranch?.name?.replace(/\s+/g, '%20')}/doctor/${id}` : `/doctor/${id}`
        //     } `;

        // navigator.clipboard.writeText(copyText)
        //     .then(() => {
        //         console.log('URL copied to clipboard:', copyText);
        //     })
        //     .catch((err) => {
        //         console.error('Failed to copy URL: ', err);
        //     });
    };

    const handleGetFollowing = () => {
        axios.get(`${URL.publish}getfollowing?userId=${id}`, {
            headers: {
                "Authorization": `Bearer ${usertoken}`
            }
        }).then(res => {
            setFollowData(res?.data)
            setBeingFollowed(res?.data?.follower)
        })
    }
    useEffect(() => {
        if (usertoken) {
            handleGetFollowing()
        }
    }, [])

    useEffect(() => {
        setIsFollowing(beingFollowed)
    }, [beingFollowed])

    const handleFollow = (index) => {
        if (usertoken) {
            setInd(index)
            setLoading(true);

            const data = {
                "userId": id
            };
            axios.post(`${URL.publish}follow`, data, {
                headers: {
                    "Authorization": `Bearer ${usertoken}`
                }
            })
                .then((res) => {
                    // Handle success response here
                    handleGetFollowing()

                    console.log("Follow successful:", res.data);
                })
                .catch((err) => {
                    // Handle error here
                    console.error("Error following:", err);
                }).finally(() => {
                    setLoading(false);
                    setInd('');
                });
        } else {
            setOpenMessage(true)
            // alert("Please login and follow");
        }
    };



    const handleUnFollowDoc = () => {
        setLoading(true)
        axios.delete(`${URL.publish}unfollow/${followData?.id}`, {
            headers: {
                "Authorization": `Bearer ${usertoken}`
            }
        }).then((res) => {
            handleGetFollowing()
            setModal(false)
        }).catch((err) => {
            // Handle error here
            console.error("Error following:", err);
        }).finally(() => {
            setLoading(false);
        });
        setIsFollowing(!isFollowing);
    }
    useEffect(() => {
        axios.get(`${urls.fhir_tenant_orgId}Location`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': 'fhirparimitha',
                'Realm': 'parimitha'
            }
        }).then(res => {
            setAddress(res?.data.entry?.[0]?.resource?.address)
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const handleClose = () => {
        setOpen(false)
    }



    return (
        <div>
            <Helmet>
                <title>{`${userData?.name?.[0]?.text}-doctor | Paramitha Hospitals`}</title>
                {/* <meta name="description" content={state.about} /> */}
                <meta name="canonical" content={url1} />
                <meta name="keywords" content={`${userData?.name?.[0]?.text}, "Paramitha Hospitals", ${userData?.name?.[0]?.text} `} />
                <meta property="image" content={userData?.photo?.[0]?.url ? userData?.photo?.[0]?.url : userData?.gender === "female" ? 'https://paramith-website-contents.s3.ap-south-1.amazonaws.com/FemaleDoctor-NoPhoto.svg' : 'https://paramith-website-contents.s3.ap-south-1.amazonaws.com/MaleDoctor-no-photo.svg'} />
            </Helmet>

            <div style={{
                position: 'sticky',
                top: 80,
                zIndex: 10,
            }}>
                <Card className='px-4 pt-3' sx={{ p: { sm: '10px 40px 0px 40px', md: '10px 80px 0px 80px', lg: '10px 100px 0px 100px', xl: '10px 125px 0px 125px' } }}>
                    <div className={isXsScreen ? 'none' : 'flex items-center justify-between'}>
                        <div className='flex gap-4'>
                            <CardMedia
                                sx={{
                                    height: 90,
                                    width: 90,
                                    border: '3px solid #fff',
                                    borderRadius: '12px',
                                    padding: "3px",
                                    background: '#C6C6C6',
                                    objectFit: 'fill',
                                }}
                                className="shadow-img"
                                component="img"
                                alt="gdoctor img"
                                width={130}
                                height="140"
                                image={userData?.photo?.[0]?.url ? userData?.photo?.[0]?.url : userData?.gender === 'female' ? FemaleDoctorImg : maleDoctorImg} />

                            <Stack direction={"column"} spacing={0.2}>
                                <Typography sx={{ color: "#000000", textTransform: 'capitalize', fontSize: { xs: '16px', md: "18px" }, fontWeight: 500 }}>
                                    {userData?.name?.[0]?.text}
                                </Typography>
                                <Typography sx={{ color: "#707070", fontSize: "11px" }}>
                                    {userData?.name?.[0]?.suffix?.length > 0 ? (
                                        userData?.name?.[0]?.suffix?.map((item, index) => (
                                            <span key={index}>
                                                {item}{index < userData?.name[0]?.suffix?.length - 1 ? ', ' : ''}
                                            </span>
                                        ))
                                    ) : null}
                                </Typography>
                                <Typography sx={{ color: "#000000", textTransform: 'capitalize', fontSize: { xs: 14, md: 16 } }} className="text-sm">
                                    {practitionerrole?.resource?.identifier?.[0]?.value}
                                </Typography>
                                <Typography sx={{ color: "#816565", fontSize: "14px", textTransform: 'capitalize', display: "flex", alignItems: "center", gap: "2px" }}>
                                    {practitionerrole?.resource?.specialty?.[0]?.coding?.[0]?.display && (
                                        <img src={StethIcon} width={20} height={20} alt="stethoscope" sx={{ fontSize: "12px", mr: "5px" }} />
                                    )}
                                    {practitionerrole?.resource?.specialty?.[0]?.coding?.[0]?.display}
                                </Typography>

                                {/* Communication Section Fix */}
                                <Box className="text-sm pt-1 flex font-semibold items-center justify-between">
                                    {userData?.communication?.length > 0 ? (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
                                            <img src={CommunicationImg} alt="communication" width={20} />
                                            <Typography variant="body2" sx={{ fontWeight: 500, fontSize: { xs: 14, md: 16 } }}>
                                                Communication in
                                            </Typography>
                                            {userData?.communication?.map((lang, index, arr) => (
                                                <Typography key={lang?.coding?.[0]?.display} sx={{ color: "#051984", fontWeight: 500, whiteSpace: 'nowrap', fontSize: { xs: '13px', md: '14px' } }}>
                                                    {lang?.coding?.[0]?.display}
                                                    {index < arr.length - 1 && ','}
                                                </Typography>
                                            ))}
                                        </Box>
                                    ) : <Box></Box>}
                                </Box>
                            </Stack>
                        </div>

                        {/* Buttons */}
                        <div>
                            <div className={`flex ${isXsScreen ? 'justify-end' : 'none'} gap-5 mt-1`}>
                                <Button
                                    variant="outlined"
                                    startIcon={<EventAvailable />}
                                    sx={{
                                        width: { xs: "100%", sm: "130px", md: "100%", lg: "100%", xl: '100%' },
                                        height: "30px",
                                        fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "12px", xl: "14px" },
                                        justifyContent: 'center',
                                        borderColor: '#205072',
                                        textTransform: 'capitalize',
                                        borderRadius: "7px",
                                        color: '#205072',
                                        ":hover": {
                                            bgcolor: '#205072',
                                            color: '#FFFFFF'
                                        },
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "8px",
                                    }}
                                    onClick={() => setOpenShedule(true)}
                                >
                                    <div style={{ textAlign: 'center' }}>Schedule Visit</div>
                                </Button>
                            </div>
                            <div className='flex justify-end gap-5 mt-2'>
                                {isFollowing ? (
                                    <Button
                                        variant="outlined"
                                        startIcon={<img src={followIcon} width={20} height={20} alt="telemedicine" />}
                                        sx={{
                                            height: "30px",
                                            fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px" },
                                            textTransform: "capitalize",
                                            borderRadius: "7px",
                                            borderColor: "#205072",
                                            color: "#205072",
                                            width: isXsScreen ? "100%" : 'none',
                                            "&:hover": { borderColor: "#205072" },
                                        }}
                                        onClick={() => setModal(true)}
                                    >
                                        {"following"}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        startIcon={<img src={followWhiteIcon} width={20} height={20} alt="telemedicine" />}
                                        sx={{
                                            height: "30px",
                                            fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px" },
                                            justifyContent: isXsScreen ? 'none' : 'space-around',
                                            textTransform: "capitalize",
                                            borderRadius: "7px",
                                            bgcolor: "#205072",
                                            width: { xs: "100%", md: '122px' },
                                            "&:hover": { bgcolor: "#205072" },
                                        }}
                                        onClick={() => handleFollow(ind)}
                                    >
                                        {"follow"}
                                    </Button>
                                )}
                                <Button
                                    variant="outlined"
                                    startIcon={<ShareOutlined sx={{ fontSize: 12 }} />}
                                    sx={{
                                        height: "30px",
                                        fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px" },
                                        textTransform: "capitalize",
                                        borderRadius: "7px",
                                        borderColor: "#205072",
                                        color: "#205072",
                                        width: isXsScreen ? "100%" : 'none',
                                        "&:hover": {
                                            bgcolor: "#205072", color: '#FFFFFF'
                                        },
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Share Refer
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/* <div className='flex justify-end gap-5 mt-1'>
                        <Button
                            variant="outlined"
                            startIcon={<img src={followIcon} width={20} height={20} alt="tele" />}
                            sx={{
                                height: "30px",
                                fontSize: { xs: "14px", lg: "16px" },
                                // justifyContent: 'space-between',
                                // padding:'5px',
                                textTransform: "capitalize",
                                borderRadius: "10px",
                                borderColor: "#205072",
                                color: "#205072",
                                "&:hover": { borderColor: "#205072" },
                            }}
                            onClick={() => {
                                if (loading === false) {
                                    if (isFollowing) {
                                        setModal(true)
                                    } else {
                                        handleFollow(ind);
                                    }
                                    // setIsFollowing(!isFollowing);
                                } else {
                                    return;
                                }
                            }}

                        >
                            {isFollowing ? "following" : "follow"}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<ShareOutlined sx={{ fontSize: 12 }} />}
                            sx={{
                                height: "30px",
                                fontSize: { xs: "14px", lg: "16px", },
                                // justifyContent: 'space-between',
                                // padding:'5px',
                                textTransform: "capitalize",
                                borderRadius: "10px",
                                borderColor: "#205072",
                                color: "#205072",
                                "&:hover": { borderColor: "#205072" },
                            }}
                            onClick={() => setOpen(true)}
                        >
                            Refer
                        </Button>
                    </div> */}
                    <hr style={{ marginTop: '10px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={value} onChange={handleChange} indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example" sx={{ padding: 0, '.MuiTabs-indicator': { bottom: 8 } }}
                            textColor="inherit" >
                            <Tab label="Overview" {...a11yProps(0)} sx={{ textTransform: 'capitalize', paddingTop: '0px', paddingBottom: '0px', }} />
                            <Tab label="Medical Services"  {...a11yProps(1)} sx={{ textTransform: 'capitalize', paddingTop: '0px', paddingBottom: '0px', }} />
                            <Tab label="Post & Publications" {...a11yProps(2)} sx={{ textTransform: 'capitalize', paddingTop: '0px', paddingBottom: '0px', }} />
                        </Tabs>
                    </Box >

                </Card >
            </div>
            {/* <Box sx={{ height: 'calc(100vh - 200px)', mt: 2, overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}> */}

            <Box className='px-4 pt-3' sx={{ p: { sm: '10px 40px 0px 40px', md: '10px 80px 0px 80px', lg: '10px 100px 0px 100px', xl: '10px 125px 0px 125px' } }}>

                < TabPanel value={value} index={0} >
                    <Overview userData={userData} practitionerrole={practitionerrole} doctorData={doctorData} address={address} selectedbranch={selectedbranch} />
                </TabPanel >
                <TabPanel value={value} index={1}>
                    <MedicalService userData={userData} practitionerrole={practitionerrole} selectedbranch={selectedbranch} setOpenShedule={setOpenShedule} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <PostPublications userData={userData} practitionerrole={practitionerrole} selectedbranch={selectedbranch} id={id} />
                </TabPanel>
                {/* </Box> */}
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <Typography sx={{ p: '15px', backgroundColor: '#E44190', color: 'white' }}>
                        Invite Your Peers
                    </Typography>
                    <CustomRow>
                        <WhatsappShareButton
                            windowWidth='800px'
                            windowHeight='800px'
                            url={url1}
                            title={title}
                            onShareWindowClose={() => handleClose}
                        >
                            <CustomButton name="share Profile" >
                                <WhatsApp className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </WhatsappShareButton>

                        <TwitterShareButton
                            windowWidth='800px'
                            windowHeight='800px'
                            url={url1}
                            title={title}
                        >
                            <CustomButton name="share in Twitter">
                                <Twitter className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </TwitterShareButton>
                    </CustomRow>


                    <CustomRow>
                        <LinkedinShareButton
                            url={url1}
                            title={title}
                        // summary={`${state.about}`}
                        >
                            <CustomButton name="share in Linkedin" >
                                <LinkedIn className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </LinkedinShareButton >
                        <FacebookShareButton
                            windowWidth='800px'
                            windowHeight='800px'
                            url={url1}
                            quote={`${userData?.name?.[0]?.text}  | Paramitha Hospitals`}
                            hashtag='#paramitha hospitals'
                        >
                            <CustomButton name="share in Facebook">
                                <Facebook className='inside_icon' sx={{ color: 'black' }}></Facebook>
                            </CustomButton>
                        </FacebookShareButton>
                    </CustomRow >

                    <CustomRow true={true}>
                        <EmailShareButton
                            url={url1}
                            quote={`${userData?.name?.[0]?.text} | Paramitha Hospitals`}
                            // url={`https://www.docsteth.com/myprofile/${name}-${id}`}
                            // separator={"    =>  "}
                            // subject={`${state.fullName} - ${user.caption} | Docsteth `}
                            body={`${doctorData?.about}` || ''}
                        >
                            <CustomButton name="Email Invite">
                                <Email className='inside_icon' sx={{
                                    color: 'black', width: '20px',
                                    height: '20px',
                                }} />
                            </CustomButton>
                        </EmailShareButton>
                        {/* <CustomButton name="SMS">
                                                            <SmsIcon className='inside_icon' sx={{ color: 'black' }} />
                                                            </CustomButton> */}
                        <div onClick={HandleCopy}>
                            <CustomButton name="Copy Invite" >
                                <ContentCopy className='inside_icon' sx={{ color: 'black' }} />
                            </CustomButton>
                        </div>
                    </CustomRow>

                    <Typography className="text-center  cursor-pointer" onClick={handleClose} sx={{ mt: 1, p: '10px', backgroundColor: '#FDF3F7', fontWeight: '600', color: '#0f3f5d' }}>
                        Done
                    </Typography>
                </Box >
            </Dialog >

            <DoctorUnfollow open={modal} handleClose={() => setModal(false)} handleUnFollowDoc={handleUnFollowDoc} />
            <NoAccess open={openMessage} handleClose={() => setOpenMessage(false)} />

        </div >
    )
}

export default DocProfileHeader