import React, { useState } from 'react'
import { Box, Typography, Tabs, Tab, Paper, useMediaQuery } from '@mui/material'
import Myprofile from './healthProfile/Myprofile';
import Allergies from './healthProfile/Allergies';
import SocialHistory from './healthProfile/SocialHistory';
import FamilyHistory from './healthProfile/FamilyHistory';
import HealthprofileIcon from '../../assets/healthportal-icons/healthprofile.svg'
import { BorderBottom } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HealthProfile() {
    const location = useLocation()
    const [tabValue, setTabValue] = useState(0);
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const isMobile = useMediaQuery('(max-width:600px)');
    const patientDetails = useSelector((state) => state.paramitha.patientData)
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const [selectedTab, setSelectedTab] = useState('myprofile')
    const handleOntab = () => {
        setSelectedTab()
    }
    const tabData = [
        { label: "My Profile", item: 'myprofile' },
        { label: "Allergies", item: 'allergies' },
        { label: "Family History", item: 'familyhistory' },
        { label: "Social History", item: 'socialhistory' }
    ];
    const handleOnTabChange = (item) => {
        setSelectedTab(item)
    }

    return (
        <div>
            <Box>
                {!isXsScreen &&
                    <Typography color={'#155775'} fontWeight={600} sx={{ display: 'flex', alignItems: 'center', gap: "10px" }}> <img src={HealthprofileIcon} alt="health" />
                        My Health Profile
                    </Typography>
                }
                <Box sx={{
                    display: 'flex',
                    gap: { xs: '20px', sm: '20px', md: '20px' },
                    justifyContent: { xs: 'space-between', sm: 'flex-start', md: 'flex-start' },
                    alignItems: 'flex-start',
                    mt: {
                        xs: '1px', md: '10px'
                    },
                }
                } >
                    {
                        tabData.map((tab, index) =>
                            <Typography key={index} sx={{ fontSize: { xs: '14px', sm: '16px', md: '16px' }, borderBottom: selectedTab === tab.item ? "2px solid #155755" : 'transparent', cursor: 'pointer', color: selectedTab === tab.item ? '#155775 !important' : '#888888' }} onClick={() => handleOnTabChange(tab.item)}>{tab.label}</Typography>
                        )
                    }
                </Box>

                {/* <Box sx={{ bgcolor: 'background.paper' }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant={isMobile ? "scrollable" : "standard"}
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {tabData.map((tab, index) => (
                            <Tab key={index} label={tab.label} sx={{ textTransform: 'capitalize', borderBottom: tabValue === index ? "2px solid #155755" : 'transparent', fontSize: '16px', color: tabValue === index ? '#155775 !important' : '#888888' }} />
                        ))}
                    </Tabs>
                </Box> */}
                {/* <Tabs value={tabValue} onChange={handleTabChange} TabIndicatorProps={{ sx: { display: "none" } }}
                    variant={isMobile ? "scrollable" : "standard"}

                    scrollButtons={'auto'} >
                    {tabData.map((tab, index) => (
                        <Tab key={index} label={tab.label} sx={{ textTransform: 'capitalize', borderBottom: tabValue === index ? "2px solid #155755" : 'transparent', fontSize: '16px', color: tabValue === index ? '#155775 !important' : '#888888' }} />
                    ))}
                </Tabs> */}
                {/* <Tabs
                    sx={{
                        ".MuiTab-root": { minHeight: "5px", mt: { xs: "7px", sm: '11px' }, p: '8px 14px', display: 'flex', alignItems: 'center', justifyContent: 'center' },

                    }}
                    value={tabValue}
                    onChange={handleTabChange}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    TabIndicatorProps={{ sx: { display: "none" } }}
                >
                    {tabData.map((tab, index) => (
                        <Tab key={index} label={tab.label} sx={{ textTransform: 'capitalize', borderBottom: tabValue === index ? "2px solid #155755" : 'transparent', fontSize: '16px', color: tabValue === index ? '#155775 !important' : '#888888' }} />

                    ))}
                </Tabs> */}
                {/* {tabData.map((tab, index) => (
                <div key={index} style={{ display: tabValue === index ? 'block' : 'none' }}>
                    <Box>
                        <Typography>{tab.content}</Typography>
                    </Box>
                </div>
            ))} */}
                <Paper elevation={2} sx={{ mt: "20px", p: "10px 15px" }}>
                    {/* <Box sx={{
                    overflowY: 'scroll', height: '83vh', '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}> */}
                    {selectedTab === "myprofile" && <Myprofile patientDetails={patientDetails} selectedbranch={selectedbranch} />}
                    {selectedTab === "allergies" && <Allergies />}
                    {selectedTab === "familyhistory" && <FamilyHistory />}
                    {selectedTab === "socialhistory" && <SocialHistory />}
                    {/* </Box> */}
                </Paper>
            </Box >
        </div >
    )
}

export default HealthProfile