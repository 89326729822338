import React from 'react'
import {
    Autocomplete,
    Box,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CustomSlick from "./CustomSlick";
import { useSelector } from 'react-redux';

const HappyClients = () => {
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const options = ["Google Business"];
    const serviceOptions = ['Kompally', 'Kothapet', 'Chintal', 'Chandanagar', 'Medipally', 'Bachupally'];
    // const [value, setValue] = React.useState(selectBranchInfo?.name || 'Kompally');
    const [value, setValue] = React.useState('Kompally');
    const [inputValue, setInputValue] = React.useState("");


    const [serviceValue, setServiceValue] = React.useState("Google Business");
    const [inputServiceValue, setInputServiceValue] = React.useState("");
    return (
        <div style={{ backgroundColor: "#CF5478" }}>
            <div style={{ paddingTop: "15px" }}>
                <Typography
                    level="h1"
                    fontSize={30}
                    sx={{
                        textAlign: "center",
                        fontWeight: 500,
                        color: "#fff",
                        // textTransform: "uppercase",
                    }}
                >
                    Our Happy Patients
                </Typography>
                <Typography
                    level="h2"
                    fontSize={{ xs: 16, md: 20 }}
                    sx={{
                        textAlign: "center",
                        //   fontWeight: "bold",
                        color: "#fff",
                        opacity: "1",
                        textTransform: "capitalize",
                    }}
                >Our Patients Testimonials, is not just patient satisfaction, its our Achievement
                </Typography>
            </div>
            <Grid
                container
                gap={3}
                paddingTop={2}
                sx={{ paddingLeft: { xs: "34px", md: "60px" }, paddingRight: "60px" }}
            >
                <Grid item xs={12} sm={4} md={3}>
                    <Box>
                        <Autocomplete
                            value={serviceValue}
                            onChange={(event, newValue) => {
                                setServiceValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            sx={{
                                width: 300,
                                //   "& .MuiAutocomplete-inputRoot": {
                                //     height: 50,
                                //     backgroundColor: "#fff",
                                //   },
                                "& .MuiAutocomplete-inputRoot": {
                                    height: 33,
                                    backgroundColor: "#fff",
                                },
                                ".MuiOutlinedInput-root": { padding: "0px" },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Service Category"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <IconButton>
                                                <FmdGoodIcon sx={{ fontSize: "15px" }} />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Box>
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            inputValue={inputServiceValue}
                            onInputChange={(event, newInputValue) => {
                                setInputServiceValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={serviceOptions}
                            // sx={{ width: 300 }}
                            sx={{
                                width: 300,
                                // "& .MuiAutocomplete-inputRoot": {
                                //   height: 50,
                                //   backgroundColor: "#fff",
                                // },
                                "& .MuiAutocomplete-inputRoot": {
                                    height: 33,
                                    backgroundColor: "#fff",
                                },
                                ".MuiOutlinedInput-root": { padding: "0px" },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="All Facilities"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <IconButton>
                                                <FmdGoodIcon sx={{ fontSize: "15px" }} />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
            <div
                style={{
                    paddingTop: "30px",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                }}
            >
                <CustomSlick value={value} />
            </div>
            <Grid
                container
                justifyContent={"center"}
                paddingTop={6}
                paddingBottom={4}
            >
                {/* <Grid
                    sx={{
                        border: "1px solid #fff",
                        borderRadius: "12px",
                        color: "#fff",
                        padding: "5px 10px",
                        textTransform: "capitalize"
                    }}
                >
                    Show all reviews
                </Grid> */}
            </Grid>
        </div >
    )
}

export default HappyClients