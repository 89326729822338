import React, { useCallback, useState } from 'react'
import CommonDialog from '../../atoms/commonModal'
import { DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import ContainedButton from '../../atoms/commonbutton'
import { useDispatch, useSelector } from 'react-redux'
import AllPatientProfiles from '../AllPatientProfiles'
import { localstore } from '../../localstore/localstore'
import { getPatientIdDetails } from '../../../redux/actions/actions'
import BranchUrl from '../../../services/api_branch_url'
import { useNavigate } from 'react-router-dom'
import { Close } from '@mui/icons-material'

function SwitchprofilePopup({ open, setOpen }) {
    const personDetails = useSelector((state) => state.paramitha.patientDetails)
    const patientDetails = useSelector((state) => state.paramitha.patientData)

    const branchName = useSelector((state) => state.branch.branch)
    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const fixedIbmId = localstore.getPatientIbmId()
    const [loading, setLoading] = useState(false)
    const urls = BranchUrl()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleClose = () => {
        setOpen(false)
    }
    const handleSwitchProfile = useCallback(async (id, val) => {
        // console.log(id, "iddd")
        // setLoading(true);
        // if (fixedIbmId === id) {
        //     dispatch(getPatientdatafromibm(urls, usertoken, id));
        //     setLoading(false);
        //     localStorage.setItem('pId', fixedIbmId)

        //     // } else if ((fixedIbmId === id)) {
        // } else {
        dispatch(getPatientIdDetails(urls, usertoken, id))
        setLoading(false);
        localStorage.setItem('pId', id);
        handleClose()
        navigate(branchName ? `/${branchName}/patientprofile` : `/patientprofile`, { state: { id } });
        // }
    }, [fixedIbmId, usertoken, setLoading]);

    return (
        <CommonDialog open={open} >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Switch Profile</Typography>
                <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {personDetails?.link && personDetails?.link?.length > 0 ? (
                    personDetails?.link?.map((person, index) => (
                        <AllPatientProfiles
                            key={index}
                            person={person}
                            handleSwitchProfile={handleSwitchProfile}
                            patientDetails={patientDetails} />
                    ))
                ) : (
                    <div className='text-center mt-1'>No patients found. Add new patients to get started.</div>
                )}
            </DialogContent>

        </CommonDialog>)
}

export default SwitchprofilePopup