import { Box, Button, Card, CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { PostData } from "../home/healhinfor/PostData";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import URL from "../../services/api_url";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import { DynamicFeed, KeyboardArrowLeft, KeyboardArrowRight, LensOutlined, LensRounded } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { localstore } from "../localstore/localstore";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div
      className="unique-button-group-container"
      style={{ position: "absolute", top: "0", right: "0" }}
    >
      <IconButton
        aria-label="arrow-left"
        className={`carousel-arrow-button ${currentSlide === 0 ? "disable" : ""
          }`}
        onClick={() => previous()}
      >
        <KeyboardArrowLeft fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="arrow-right"
        className="carousel-arrow-button"
        onClick={() => next()}
      >
        <KeyboardArrowRight fontSize="inherit" />
      </IconButton>
    </div>
  );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li
      style={{
        cursor: "pointer",
        color: active ? "#134265" : "#fff",
        marginTop: "10px",
      }}
      onClick={() => onClick()}
    >
      {active ? (
        <LensRounded style={{ fontSize: "14px" }} />
      ) : (
        <LensOutlined style={{ fontSize: "14px", color: "#000" }} />
      )}
    </li>
  );
};
const AllPosts = ({ pageIds }) => {
  const ibmId = localstore.getIbmId()
  const usertoken = localstore.getToken()
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
  const branchName = useSelector((state) => state.branch.branch)
  const navigate = useNavigate()
  const pageId = selectedbranch?.pageId ? selectedbranch?.pageId : pageIds

  // useEffect(() => {
  //   postAPIS();
  // }, [pageId]);
  // const postAPIS = async (key) => {
  //   // console.log("hello");
  //   if (pageId) {
  //     setLoading(true);
  //     await axios.get(`${URL.feed}feed/getpostbypageid/${pageId}?limit=25&offset=0`)
  //       .then((res) => {
  //         setPosts(res.data?.results || []);
  //         // console.log(res.data.results, "555");
  //       })
  //       .catch((error) => console.log(error, "6666666666"))
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (ibmId && pageId) {
          // Perform the axios call with headers properly passed
          const response = await axios.get(
            // `${URL.publish}getPostsByStatus/publish?pageId=${pageId}`,
            `${URL.publish}getPostsByStatus/publish?postType=general_posts&pageId=${pageId}`,
            { headers: { Authorization: `Bearer ${usertoken}` } } // Correct headers
          );

          // Update state with the fetched posts
          setPosts(response?.data || []);
        } else if (pageId) {
          const response = await axios.get(
            `${URL.publish}getPostsByStatus/publish?postType=general_posts&pageId=${pageId}`,

            // `${URL.post}feed/getpostbypageid/${pageId}?limit=25&offset=0`
          );

          setPosts(response?.data || []);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
        // Handle error as needed
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [pageId, ibmId, usertoken]); // Ensure necessary dependencies are included

  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <div className="flex gap-2">
        <DynamicFeed sx={{ fontSize: '30px' }} />
        <div>
          <div className="text-xl font-semibold">
            Latest Updates
          </div>
        </div>
      </div>
      {posts && posts.length > 0 ? (

        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true}
          keyBoardControl={true}
          containerClass="carousel-container-3"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
          customDot={<CustomDot />}
          arrows={false}
        >
          {loading ? (
            <CircularProgress sx={{ display: "block", mx: "auto", mt: 3 }} />
          ) : (
            // posts && posts.length > 0 ? (
            posts.map((post, index) => (
              <PostData feedData={post} key={index} />
            ))
            // ) : (
            //   <Grid container> <Grid item md={12} textAlign={'center'} p={4} fontSize={18}>No Posts Available</Grid>
            //   </Grid>
            // )
          )}
        </Carousel>) : <Grid container> <Grid item md={12} textAlign={'center'} p={4} fontSize={18}>No Posts Available</Grid>
      </Grid>}


      {/* </div>
      </div> */}
      {/* <Grid container gap={2} sx={{ mt: "10px" }}>
        {/* {(console.log(posts), "vvv")} */}


      {/* {posts.map((post, val) => (
          <Grid item xs={12} sm={5.9} md={3.9}>
          
            <PostData feedData={post} key={val} />
         
          </Grid>
        ))}  */}
      {/* </Grid> * /} */}

      {posts && posts.length >= 3 && (
        < Box sx={{ mt: "10px", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "transparent",
              color: "black",
              boxShadow: "none",
              textTransform: "capitalize",
              marginX: "auto",
              border: "1px solid #A3A3A3",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50px",
              gap: 1,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => navigate(branchName ? `/${branchName}/allposts` : `/allposts`, { state: { posts } })}
          >
            Show All Posts
            <ArrowRightAltIcon />
          </Button>
        </Box >
      )
      }
    </Box >
  );
};

export default AllPosts;
