import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Grid, Typography, Button, CircularProgress, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { branchInfo } from "../../../redux/slices/branchSlice";
import { selectedBranchInfo } from "../../../redux/slices/SelectedBranchSlice";
import { useDispatch } from "react-redux";
import { DoctorTabCards } from "./doctorTabCards";
import MobileDoctorTab from "./mobileDoctorTab";


const DoctorTab = ({ branch }) => {
  const [practitionerRole, setPractitionerRole] = useState([])
  const [loading, setLoading] = useState(false)
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const branchId = branch?.id ? branch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

  useEffect(() => {
    if (branchId) {
      // console.log('first, 71')
      setLoading(true)
      axios.get(`${process.env.REACT_APP_API_URL_v4}parimitha/${branchId}/PractitionerRole?role=doctor`, {
        headers: {
          'X-FHIR-TENANT-ID': 'parimitha',
          'Realm': 'parimitha',
          'X-FHIR-DSID': `fhirparimitha`
        }
      }).then((res) => {
        setPractitionerRole(res?.data?.entry || [])
      }).catch((err) => console.log(err)).finally(() => {
        setLoading(false)
      })
    }
  }, [branch])

  const handleFindAllDoctors = () => {
    localStorage.setItem("brName", branch?.name);
    localStorage.setItem("selectOrg", branch?.name);
    localStorage.setItem("selectedbranch", JSON.stringify(branch));
    dispatch(branchInfo(branch?.name));
    dispatch(selectedBranchInfo(branch));
    navigate(branch?.name ? `/${branch?.name}/doctors` : `/doctors`)
  }
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        mt: {
          xs: 0, md: 4
        }
      }}
    >
      <Box sx={{
        bgcolor: '#FDF3F7', p: { xs: "10px 10px", md: "20px 20px" },
      }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container columnSpacing={5} rowSpacing={4} className={isXsScreen ? "px-0 py-0" : "px-6 py-6"}>
            {practitionerRole?.length > 0 ?
              practitionerRole?.slice(0, 4)?.map((val, index) => {
                return (
                  <Grid item xs={12} sm={6} key={index} md={6} lg={6} alignItems={'flex-start'} sx={{
                    p: {
                      xs: "10px 0px", md: "0px 0px"
                    }
                  }
                  } >
                    {isXsScreen ? <MobileDoctorTab val={val} branch={branch} /> :
                      <DoctorTabCards val={val} branch={branch} />}
                  </Grid>
                );
              }) : <Grid item xs={12} sm={12} md={12} lg={12} alignItems={'flex-start'} sx={{ p: "10px", textAlign: 'center', mt: 1, height: '150px' }}>
                <Typography variant="h6" sx={{ color: "#525C79" }}>
                  No Doctors available
                </Typography></Grid>

            }
          </Grid>
        )}
      </Box>

      {
        practitionerRole?.length >= 4 &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", }}>
          <Button variant="outlined"
            onClick={handleFindAllDoctors}
            sx={{
              borderColor: "#E54290", textTransform: "capitalize", color: '#E54290', width: 255, fontSize: "18px", borderRadius: "10px", mt: 2, height: "54px",
              '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
            }}
            endIcon={<ArrowForward />}
          >
            <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>  Find All Doctors</Typography>
          </Button>

        </div>
      }

      {/* <Grid item xs={12} sm={0} md={1.5} sx={{ p: "10px" }}></Grid> */}
    </Box >
  );
};

export default DoctorTab;
